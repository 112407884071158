import React from 'react';
import {useParams} from 'react-router-dom';
import Footer from '../common/footer/Footer';
import HeaderTwo from '../common/header/HeaderTwo';
import BcrumbBannerTwo from '../elements/breadcrumb/BcrumbBannerTwo';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import { slugify } from '../utils';
import ProjectData from "../data/project/ProjectData.json";
import Accordion from 'react-bootstrap/Accordion';
import { FaCompress, FaCode, FaGlobe } from 'react-icons/fa';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';

const allProjectData = ProjectData;

const ProjectDetails = () => {

    const params = useParams();
    const projectSlug = params.slug;

    const getProjectData = allProjectData.filter(data => slugify(data.title) === projectSlug);
    const detailsProject = getProjectData[0];


    return (
        <>
        <SEO title={detailsProject.title} description={detailsProject.excerpt} />
        <ColorSwitcher />
        <main className="main-wrapper">
            <HeaderTwo />
            <BcrumbBannerTwo
                title={detailsProject.title}
                paragraph ={detailsProject.excerpt}
                mainThumb={detailsProject.image}
            />
            <section className="section-padding single-portfolio-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="section-heading heading-left mb-0">
                                <span className="subtitle">
                                    {detailsProject.category.map((cat, i) => (
                                            <span key={i}>{cat}</span> 
                                        )
                                    )}
                                </span>
                                <h3 className="title">{detailsProject.title}</h3>
                            </div>
                            {detailsProject.body.map((para, index) => (
                                <p key={index} dangerouslySetInnerHTML={{__html: para}}></p>
                            ))}
                            
                            {detailsProject.url && (
                                <a className="axil-btn btn-fill-primary" href={detailsProject.url}>View website</a>
                            )}
                            
                        </div>
                        <div className="col-lg-6 offset-xl-1">
                            <div className="why-choose-us">
                                <div className="section-heading heading-left">
                                    <h3 className="title">We delivered</h3>
                                    <p>At SoftPixel, we take a comprehensive approach to every project, from initial strategy to final optimization. Our process ensures that each solution is not only visually engaging but also strategically aligned with your goals. Through thoughtful research, expert design and development, and meticulous testing, we deliver results that drive real impact and set your brand apart in the digital landscape.</p>
                                </div>
                                <Accordion defaultActiveKey="1">
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header><FaCompress /> Research & Strategy </Accordion.Header>
                                        <Accordion.Body>
                                            "Building the foundation for success with insights-driven strategies." <br />
                                            Our work begins with understanding your goals, audience, and industry landscape. We conduct thorough research to create a strategic plan tailored to your brand, setting the stage for impactful results.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header><FaCode /> Design & Development </Accordion.Header>
                                        <Accordion.Body>
                                            "Bringing ideas to life with creativity and precision."  <br />
                                            With a focus on UI/UX excellence, we craft user-friendly interfaces and engaging experiences. Our development team then transforms these designs into functional, high-performing digital products, optimized for web and mobile.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header><FaGlobe /> Testing & Optimization </Accordion.Header>
                                        <Accordion.Body>
                                            Ensuring quality through rigorous testing and continuous improvements."  <br />
                                            Before launch, we rigorously test each product to ensure flawless performance. Post-launch, we monitor, analyze, and optimize, making sure the solution evolves with your business and keeps delivering results.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <CtaLayoutOne />
            <Footer parentClass="" />
        </main>
        </>
    )
}

export default ProjectDetails;