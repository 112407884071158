import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';

const Datas = [
    {
        id: 1,
        title: "Your Vision, Our Expertise",
        para: "At SoftPixel, we don't just take on projects—we become your partner. With a personalized approach to every design and development challenge, we ensure your vision is brought to life with precision and creativity. Let us guide you through every step of the process."
    },
    {
        id: 2,
        title: "Driven by Innovation and Results",
        para: "We combine cutting-edge technology with innovative design to create impactful digital experiences. From idea to execution, our team is dedicated to delivering measurable results that help your business grow and succeed in the competitive digital landscape."
    },
    {
        id: 3,
        title: "A Team You Can Rely On",
        para: "Working with SoftPixel means you have a dedicated team of experts in UI/UX design, development, and digital marketing at your service. We value clear communication, transparency, and a results-oriented approach, ensuring that every project is completed to the highest standards."
    }
]




const AboutThree = () => {
    return (
        <div className="section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20">
            <div className="container">
                <SectionTitle 
                    subtitle="Our Valus"
                    title="Why should you work with us?"
                    description="At SoftPixel, we combine creativity, technical expertise, and a results-driven mindset to bring your digital vision to life."
                    textAlignment="heading-left heading-light-left mb--100"
                    textColor=""
                />

                <div className="row">
                    {Datas.map((data) => (
                        <div className="col-lg-4" key={data.id}>
                            <div className="about-quality">
                                <h3 className="sl-number">{data.id}</h3>
                                <h5 className="title">{data.title}</h5>
                                <p>{data.para}</p>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
            <ul className="list-unstyled shape-group-10">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-1.png"} alt="Circle" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-3.png"} alt="Circle" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"} alt="Circle" /></li>
            </ul>
        </div>
    )
}

export default AboutThree;