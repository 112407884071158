import React from 'react';
import SEO from '../common/SEO';
import Footer from '../common/footer/Footer';
import HeaderTwo from '../common/header/HeaderTwo';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ContactForm from '../component/contact/ContactForm';



const Contact = () => {
    return (
        <>
            <SEO title="Contact us" />
            <main className="main-wrapper">
            <HeaderTwo />
            <BreadCrumbOne 
                title="Contact"
                page="Contact"
            />

            <div className="section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-6">
                            <div className="contact-form-box shadow-box mb--30">
                                <h3 className="title">Get a free quote</h3>
                                <ContactForm />
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-6 offset-xl-1">
                            <div className="contact-info mb--100 mb_md--30 mt_md--0 mt--150">
                                <h4 className="title">Phone</h4>
                                <p>Our customer care is open from Mon-Fri, 10:00 am to 6:00 pm</p>
                                <h4 className="phone-number"><a href="tel:+37061404744">(+370) 6140 47 44 </a></h4>
                            </div>
                            <div className="contact-info mb--30">
                                <h4 className="title">Email</h4>
                                <p>Our support team will get back to in 48-h during standard business hours.</p>
                                <h4 className="phone-number"><a href="mailto:softpixeleu@gmail.com">softpixel.eu@gmail.com</a></h4>
                            </div>
                        </div>
                    </div>
                </div>
                <ul className="list-unstyled shape-group-12">
                    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="Bubble" /></li>
                    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Bubble" /></li>
                    <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/circle-3.png"} alt="Circle" /></li>
                </ul>
            </div>
           
            <Footer parentClass="" />
            </main>
        </>
    )
}

export default Contact;