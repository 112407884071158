import React from 'react'
import PropTypes from "prop-types";
import { Helmet } from 'react-helmet';

const SEO = ({ title, description = "Soft Pixel specializes in creating web and mobile solutions, from custom software development to intuitive UI/UX design." }) => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>SoftPixel | {title}</title>
            <meta name="robots" content="noindex, follow" />
            <meta name="description" content={description} />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        </Helmet>
    )
}
SEO.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string
};

export default SEO;