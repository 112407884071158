import React from 'react';
import { Link } from 'react-router-dom';
import HeaderTwo from '../common/header/HeaderTwo';
import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import { AnimationOnScroll } from 'react-animation-on-scroll';

const ErrorPage = () => {

    return (
        <>
        <SEO title="404 Not Found" />
        <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderTwo />
                <div className="error-page onepage-screen-area">
                    <div className="container">
                        <div className="row align-items-center">
                        <div className="col-lg-6">
                        <AnimationOnScroll  animateIn="slideInUp" duration={1} delay={300} animateOnce={true}>
                            <div className="content">
                                <h2 className="title">Oops</h2>
                                <h5>Looks Like You’re Lost in the Pixel Maze!</h5>
                                <p> Don’t worry, even the best designs have their detours. Let’s get you back on track! Try heading back to our homepage or exploring some of our key services to find what you're looking for. </p>
                                <Link to={process.env.PUBLIC_URL + "/"} className="axil-btn btn-fill-primary">Go Back</Link>
                            </div>
                        </AnimationOnScroll>
                        </div>
                        <div className="col-lg-6">
                        <AnimationOnScroll  animateIn="zoomIn" duration={1} delay={300} animateOnce={true}>
                            <div className="thumbnail">
                                <img src={process.env.PUBLIC_URL + "/images/others/404.png"} alt="404" />
                            </div>
                        </AnimationOnScroll>
                        </div>
                        </div>
                    </div>
                    <ul className="shape-group-8 list-unstyled">
                        <li className="shape shape-1">
                            <img src={process.env.PUBLIC_URL + "/images/others/bubble-9.png"} alt="Bubble" />
                        </li>
                        <li className="shape shape-2">
                            <img src={process.env.PUBLIC_URL + "/images/others/bubble-27.png"} alt="Bubble" />
                        </li>
                        <li className="shape shape-3">
                            <img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="Line" />
                        </li>
                    </ul>
                </div>
            </main>
        </>
    )
}

export default ErrorPage;