import React from 'react';
import Footer from '../common/footer/Footer';
import HeaderTwo from '../common/header/HeaderTwo';
import SEO from '../common/SEO';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import TeamOne from '../component/team/TeamOne';
import AboutThree from '../component/about/AboutThree';
import AboutFour from '../component/about/AboutFour';

const AboutUs = () => {

    return (
        <>
            <SEO title="About" description="Your Partner in Design, Development, and Digital Strategy" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderTwo />
                <BcrumbBannerOne 
                title="Passionate Minds, Pixel-Perfect Solutions"
                paragraph ="At SoftPixel, we're a team of creators, developers, and strategists dedicated to transforming ideas into impactful digital experiences. With a commitment to quality and innovation, we deliver solutions that not only meet your needs but exceed expectations."
                styleClass="thumbnail-4"
                mainThumb="/images/banner/banner-thumb-3.png"
                />
                
                <TeamOne />

                <AboutFour />

                <AboutThree /> 

                <CtaLayoutOne />
                
                <Footer parentClass="" />
            </main>
        </>
    )
}

export default AboutUs;