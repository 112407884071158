import React, {useRef, useState} from 'react';
import emailjs from '@emailjs/browser';
import Alert from 'react-bootstrap/Alert';

const Result = () => {
    return (
        <Alert variant="success" className="success-msg">
            Your Message has been successfully sent.
        </Alert>
    )
}

const ContactForm = () => {

    const form = useRef();

    const [ result, showresult ] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();

            // Gather form values using form.current
            const toName = form.current['contact-name'].value;
            const fromEmail = form.current['contact-email'].value;
            const phone = form.current['contact-phone'].value;
            const message = form.current['contact-message'].value;

            //emailjs.sendForm('service_0x2rnws', 'template_h7tblm8', form.current, 'YR7xslEk_Fshlz6IN')
            emailjs.send("service_6oowjbt","template_h7tblm8",{
                to_name: toName,
                from_email: fromEmail,
                phone: phone,
                message: message,
                reply_to: fromEmail,
            }, 'YR7xslEk_Fshlz6IN')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
            form.current.reset();
            showresult(true);
        };

        setTimeout(() => {
            showresult(false);
        }, 5000);


    return (
        <form ref={form} onSubmit={sendEmail} className="axil-contact-form">
            
            <div className="form-group">
                <label>Name</label>
                <input type="text" className="form-control" name="contact-name" required />
            </div>
            <div className="form-group">
                <label>Email</label>
                <input type="email" className="form-control" name="contact-email" required />
            </div>
            <div className="form-group">
                <label>Phone</label>
                <input type="tel" className="form-control" name="contact-phone" required />
            </div>
            <div className="form-group mb--40">
                <label>How can we help you?</label>
                <textarea className="form-control" name="contact-message" rows="4"></textarea>
            </div>
            <div className="form-group">
                <button type="submit" className="axil-btn btn-fill-primary btn-fluid btn-primary" name="submit-btn">Send Now</button>
            </div>
            <div className="form-group">
                {result ? <Result /> : null}
            </div>

        </form>
    )
}

export default ContactForm;