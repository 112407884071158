import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaLinkedin, FaInstagram } from "react-icons/fa";


const SplashFooter = () => {
    return (
        <footer className="footer-area splash-footer">
            <div className="container">
                <div className="footer-bottom">
                    <div className="row align-items-center">
                        <div className="col-lg-4">
                        <div className="footer-copyright">
                            <span className="copyright-text">© {new Date().getFullYear()}. All rights reserved by SoftPixel.</span>
                        </div>
                        </div>
                        <div className="col-lg-4">
                        <ul className="footer-social list-unstyled">
                            {/* <li><Link to="https://www.facebook.com/"><FaFacebookF /></Link></li>
                            <li><Link to="https://www.linkedin.com/"><FaLinkedin /></Link></li> */}
                            <li><a href="https://www.instagram.com/softpixel.eu" target='_blank'><FaInstagram /></a></li>
                        </ul>
                        </div>
                        <div className="col-lg-4">
                            <div className="footer-bottom-link">
                                <ul className="list-unstyled">
                                    <li><Link to={process.env.PUBLIC_URL + "/services"}>Services</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/portfolio"}>Portfolio</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + "/contact"}>Contac us</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    )
}

export default SplashFooter;