import React from 'react';
import Footer from '../common/footer/Footer';
import HeaderTwo from '../common/header/HeaderTwo';
import SEO from '../common/SEO';
import BannerTwo from '../component/banner/BannerTwo';
import BrandOne from '../component/brand/BrandOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ProjectOne from '../component/project/ProjectOne';
import ServicePropOne from '../component/service/ServicePropOne';
import SectionTitle from '../elements/section-title/SectionTitle';


const CreativeAgency = () => {

    return (
        <>
        <SEO title="Home" desc="Expert UI/UX Design, Web & Mobile Development, and Digital Marketing Solutions"/>
        <main className="main-wrapper">
            <HeaderTwo />
            <BannerTwo />
            <div className="section section-padding">
                <div className="container">
                <SectionTitle 
                    subtitle="What We Can Do :)"
                    title="Transforming Ideas <br /> into Digital Success"
                    description=""
                    textAlignment="heading-left mb--20 mb_md--70"
                    textColor=""
                />
                    <div className="row">
                        <ServicePropOne colSize="col-lg-4" serviceStyle="service-style-2" itemShow="3" marginTop="yes"/>
                    </div>
                </div>
                <ul className="shape-group-7 list-unstyled">
                    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-2.png"} alt="circle" /></li>
                    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="Line" /></li>
                    <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Line" /></li>
                </ul>

            </div>
            <ProjectOne parentClass="bg-color-light"/>
            <BrandOne />
            <CtaLayoutOne />
     
            <Footer parentClass="" />
        </main>
        </>
    )
}

export default CreativeAgency;

